<template>
	<div class='a-menu-dropdown' :class="{ open: open }" v-click-outside="closeSelect">
		<div class="a-menu-dropdown__button" @click="open = !open">
			<v-icon icon='dotted' size='18' />
		</div>
		<div class="a-menu-dropdown__body" v-if='open'>
			<ul class='a-menu-dropdown--list'
				v-for='(item, i) in items' :key='i'
			>
				<li class='a-menu-dropdown--item' @click='actionCallback(item.callback)'>
					<p class='a-menu-dropdown--title' :class='item.color ? item.color : null'>{{item.name}}</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			open: false
		}),
		props: ['items', 'item'],
		methods: {
			actionCallback(callback) {
				this.closeSelect();
				return typeof callback === "function" ? callback(this.item) : () => {};
			},
			closeSelect() {
				this.open = false
			}
		}
	}
</script>


<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-menu {
		&-dropdown {
			&__button {
				padding: 5px 4px;
				margin-left: 12px;
				color: rgba($cyprus, .4);
				cursor: pointer;
				transition: color $transition;
				&:hover {
					color: rgba($cyprus, .8);
				}
			}
			&__body {
				z-index: 1;
				position: absolute;
				top: 100%;
				right: 0;
				min-width: 140px;
				margin-top: 4px;
				background-color: $white;
				box-shadow: 0px 4px 30px rgba(86, 86, 86, 0.2);
				border-radius: 10px;
				overflow: hidden;
			}
			&--item {
				padding: 12px 15px;
				cursor: pointer;
				transition: background-color $transition;
				&:hover {
					background-color: $cyan-light;
				}
			}
			&--title {
				font-size: 14px;
				line-height: 1;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
</style>
