<template>
	<div class="v-alert" :class='classes' :style='customBackground ? `background-color: ${customBackground}` : null'>
		<div class="v-alert__wrapper">
			<span class="v-alert__icon" v-if='type || icon'>
				<v-icon :icon='icon ? icon : type' />
			</span>
			<div class="v-alert__content">
				<slot />
			</div>
			<div class="v-alert__action ml-auto" v-if='dismissible'>
				<div class='v-alert__action-close' @click='$emit("close-alert")'>
					<v-icon icon='cross' size='12' />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			type: {
				type: [String, Boolean],
				default: false
			},
			icon: {
				type: [String, Boolean],
				default: false
			},
			color: {
				type: [String, Boolean],
				default: false
			},
			border: {
				type: [String, Boolean],
				default: false
			},
			dismissible: {
				type: Boolean,
				default: false
			},
			outlined: {
				type: Boolean,
				default: false
			},
			text: {
				type: Boolean,
				default: false
			},
			"custom-background": {
				type: [String, Boolean],
				default: false
			},
			small: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			classes() {
				return {
					"v-alert--outlined": this.outlined,
					[this.border ? `v-alert--border-${this.border}` : false]: this.border,
					[this.text ? this.type + '--text' : this.type]: this.type && !this.color && !this.customBackground,
					[this.color ? this.color + '--text' : this.color]: this.color && !this.customBackground,
					"v-alert--custom-background": this.customBackground,
					"v-alert--small": this.small,
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.v-alert {
		border-radius: $border-radius;
		overflow: hidden;
		margin-bottom: 20px;
		&--outlined,
		&--custom-background {
			.v-alert__wrapper:before {
				background-color: transparent !important;
			}
		}
		&--outlined {
			border: 1px solid currentColor;
		}
		&--small {
			.v-alert {
				&__wrapper {
					padding: 16px;
				}
				&__icon {
					margin-right: 10px;
					color: rgba($black, .5);
					.app-icon {
						width: 16px !important;
						height: 16px !important;
					}
				}
				&-title {
					font-size: 13px;
					font-weight: 400;
				}
				&-text {
					font-size: 12px;
				}
			}
		}
		&__wrapper {
			padding: 15px;
			display: flex;
			@include minw( $grid-breakpoints-sm ) {
				padding: 20px;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				opacity: .15;
				background-color: currentColor;
			}
		}
		&-title {
			font-size: 16px;
			font-weight: 700;
			line-height: 1.5;
			color: currentColor;
			margin-bottom: 2px;
			display: flex;
		}
		&-text {
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
			color: rgba($black, .8);
			@include minw( $grid-breakpoints-sm ) {
				font-size: 16px;
			}
		}
		&-link {
			font-size: 16px;
			font-weight: 700;
			display: inline-flex;
			align-items: center;
			@include maxw( $grid-breakpoints-sm - 1 ) {
				display: flex;
			}
			.app-icon {
				margin-left: 10px;
				svg {
					fill: none;
					stroke: currentColor;
				}
			}
			&:hover {
				color: $green-persian;
			}
			&.green--text {
				&:hover {
					color: $green-dark !important;
				}
			}
		}
		&__icon {
			margin-right: 10px;
			@include minw( $grid-breakpoints-sm ) {
				margin-right: 16px;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				.app-icon {
					width: 17px !important;
					height: 17px !important;
				}
			}
		}
		&__action {
			padding-left: 10px;
			@include minw( $grid-breakpoints-sm ) {
				padding-left: 16px;
			}
			&-close {
				padding: 5px;
				cursor: pointer;
				color: rgba($black, .6);
				@include minw( $grid-breakpoints-sm ) {
					padding: 10px;
				}
				&:hover {
					color: $green-persian;
				}
			}
		}
		&--border {
			&-left {
				border-left: 5px solid currentColor;
			}
		}
	}
</style>
