export default [
	{
		path: '404',
		name: '404',
		meta: { all: true, guest: true, requiresAuth: true },
		component: () => import('@/views/main/404')
	},
	{
		path: 'privacy-policy',
		name: 'privacy-policy',
		meta: { all: true, guest: true, requiresAuth: true },
		component: () => import('@/views/hc/privacy-policy')
	},
	{
		path: 'cookie',
		name: 'cookie',
		meta: { all: true, guest: true, requiresAuth: true },
		component: () => import('@/views/hc/cookie')
	},
	{
		path: '*',
		redirect: { name: '404' }
	}
]
