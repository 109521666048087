import { i18n } from '@/plugins/i18n'
import { Trans } from "@/plugins/Translation";
import { updateUser } from "@/services/request";

export default {
    data: () => ({
        language: null,
        currency: null,
        loading_language: false,
        loading_currency: false
    }),
    computed: {
        locales() {
            return [
                { code: "uk", name: i18n.t("account.locales.uk") },
                { code: "ru", name: i18n.t("account.locales.ru") },
                { code: "en", name: i18n.t("account.locales.en") }
            ]
        },
        exchange_rates() {
            return this.$store.state.exchange_rates.map(el => {
                return {
                    ...el,
                    name: el.code
                }
            })
        }
    },
    mounted() {
        this.language = Trans.currentLanguage;
        if(this.$store.state.currency) {
            this.currency = this.$store.state.currency;
        }
    },
    methods: {
        changeLanguage(lang) {
            if(Trans.currentLanguage === this.language) return;
            this.loading_language = true;

            updateUser({ language: this.language }).then(res => {
                this.$store.commit("auth/user", res.data);
                Trans.changeLanguage(lang).then(() => {
                    document.title = this.$t(`routes.${this.$route.name}`) + ' - ' + process.env.VUE_APP_TITLE;
                    this.$router.push({params: {lang: Trans.defaultLanguage === lang ? undefined : lang}, name: this.$route.name});
                    this.$store.commit('notification/successMessage', {title: this.$t("account.settings.changes_saved")});
                });
            }).finally(() => this.loading_language = false)
        },
        changeCurrency() {
            this.loading_currency = true;

            updateUser({ currency: this.currency }).then(res => {
                this.$store.commit("auth/user", res.data);
                this.$store.commit('currency', this.currency);
                this.$store.commit('notification/successMessage', {title: this.$t("account.settings.changes_saved")});
            }).finally(() => this.loading_currency = false)
        }
    },
    watch: {
        "$store.state.currency"() {
            this.currency = this.$store.state.currency;
        }
    }
}