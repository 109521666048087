import store from "@/store";
import { i18n } from '@/plugins/i18n';

export const common = {
	declOfNum(number, words) {
		return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]];
	},
	numberWithSpaces(x) {
		let parts = x.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		return parts.join(".");
	},
	isoCurrencies(code) {
		switch (code) {
			case 'UAH':
				return i18n.t("account.uah");
			case 'USD':
			case 'USA':
				return '$';
			case 'EUR':
				return '€';
			default:
				return code;
		}
	},
	amount(number, currency, toFixed = 0) {
		if(store.state.currency && store.state.exchange_rates.length) {
			let initialExchange = store.state.exchange_rates.find(el => el.code === store.state.currency);
			let exchange = store.state.exchange_rates.find(el => el.code === currency);

			return `${this.numberWithSpaces((Number(initialExchange.rate) / Number(exchange.rate) * Number(number)).toFixed(toFixed))} ${this.isoCurrencies(store.state.currency)}`
		} else {
			return `${this.numberWithSpaces(Number(number).toFixed(toFixed))} ${this.isoCurrencies(currency)}`
		}
	},
	typeIcon(type) {
		switch (type) {
			case 'flight':
				return 'flights';
			case 'hotel':
				return 'hotels';
			case 'transfer':
				return 'transfers';
			case 'rentTransfer':
				return 'cars';
			case 'charterFlight':
				return 'flights';
			case 'insurance':
				return 'insurance';
			case 'visa':
				return 'visa';
			default:
				return 'flights';
		}
	}
}
