var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-dropdown',{staticClass:"a-select",class:{
    'a-select-xxsmall': _vm.xxsmall,
    'a-select-xsmall': _vm.xsmall,
    'a-select-small': _vm.small,
  },attrs:{"class-dropdown":"a-select__dropdown","invalid":_vm.errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.label)?_c('label',{staticClass:"app-label"},[_vm._v(" "+_vm._s(_vm.label)+": "),(_vm.required)?_c('sup',[_vm._v("*")]):_vm._e()]):_vm._e()]},proxy:true},{key:"field",fn:function(){return [(_vm.hasSlot('field'))?[_c('div',{staticClass:"a-select__field"},[_vm._t("field")],2)]:[_c('div',{staticClass:"a-select__field"},[(_vm.innerValue && _vm.innerValue.name)?_c('div',{staticClass:"selected"},[_vm._v(" "+_vm._s(_vm.innerValue.name)+" ")]):_c('div',{staticClass:"placeholder"},[_vm._v(_vm._s(_vm.placeholder))])])],_vm._t("count"),(_vm.appendIcon)?_c('div',{staticClass:"a-select__append-inner"},[_c('v-icon',{attrs:{"icon":_vm.appendIcon,"size":"20"}})],1):_vm._e()]},proxy:true},{key:"dropdown",fn:function(ref){
  var closeSelect = ref.closeSelect;
return [_c('ul',{staticClass:"a-select__dropdown--list"},_vm._l((_vm.options),function(option,i){return _c('li',{key:i,staticClass:"a-select__dropdown--item",class:{
          active:
            option[_vm.itemValue] === (_vm.innerValue ? _vm.innerValue[_vm.itemValue] : null),
          disabled: _vm.checkDisabled(option),
        },on:{"click":function($event){$event.stopPropagation();_vm.$emit('input', option[_vm.itemValue]), closeSelect()}}},[(_vm.hasSlot('dropdown-list-item'))?[_vm._t("dropdown-list-item",null,{"option":option})]:[_c('span',[_vm._v(_vm._s(option.name))])],_c('v-icon',{staticClass:"a-select__dropdown--check ml-auto",attrs:{"icon":"check","size":"12"}})],2)}),0)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }