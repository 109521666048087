<template>
	<v-dialog :value='value' :width='568' class='a-dialog-delete' class-content='border-radius a-dialog-delete-wrap' @close-dialog="$emit('close-dialog')">
		<div class='a-dialog-delete-content'>
			<p class='a-dialog-delete-title'>{{title}}</p>
		</div>
		<div class='a-dialog-delete-footer'>
			<div class='a-dialog-delete-footer-col'>
				<v-button block xxsmall color='app-link green--text' @click.stop="$emit('close-dialog')">{{ cancelButton }}</v-button>
			</div>
			<div class='a-dialog-delete-footer-col'>
				<v-button block xxsmall :color='`${color} white--text`' @click="$emit('action-dialog')" :loading='loading'>{{nameButton}}</v-button>
			</div>
		</div>
	</v-dialog>
</template>

<script>
	import { i18n } from '@/plugins/i18n'

	export default {
		props: {
			value: Boolean,
			title: String,
			nameButton: {
				type: String,
				default: i18n.t("account.buttons.remove")
			},
			cancelButton: {
				type: String,
				default: i18n.t("account.buttons.cancel")
			},
			color: {
				type: String,
				default: 'red'
			},
			loading: {
				type: Boolean,
				default: false
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-dialog-delete {
		.app-popup {
			z-index: 20;
		}
		.backdrop {
			z-index: 15;
		}
		&-wrap {
			z-index: 5;
			padding: 0 !important;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				max-width: 100% !important;
				border-radius: 0 !important;
				display: grid;
				grid-template-rows: 1fr auto;
			}
		}
		&-content {
			padding: 40px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				padding: 55px 20px 20px;
			}
		}
		&-title {
			font-size: 28px;
			font-weight: 700;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				font-size: 20px;
				font-weight: 400;
			}
		}
		&-footer {
			border-top: 1px solid rgba($black, .1);
			padding: 20px;
			display: flex;
			justify-content: flex-end;
			&-col {
				margin-left: 20px;
			}
			.app-btn {
				min-width: 93px !important;
				@include minw( $grid-breakpoints-xs ) {
					min-width: 120px !important;
				}
			}
			@include minw( $grid-breakpoints-xs ) {
				padding: 15px 35px;
			}
		}
	}
</style>
