export default [
	{
		path: 'crm',
		meta: { requiresAuth: true, crm: true },
		component: {
			render: h => h('router-view')
		},
		redirect: { name: 'my-orders' },
		children: [
			{
				path: 'my-settings',
				name: 'my-settings',
				meta: { requiresAuth: true, crm: true },
				component: () => import('@/views/crm/my-settings')
			},
			{
				path: 'my-orders',
				meta: { requiresAuth: true, crm: true },
				component: {
					render: h => h('router-view')
				},
				children: [
					{
						path: '',
						name: 'my-orders',
						meta: { requiresAuth: true, crm: true },
						component: () => import('@/views/crm/my-orders')
					},
					{
						path: ':id',
						name: 'my-order',
						meta: { requiresAuth: true, crm: true },
						component: () => import('@/views/crm/my-order')
					}
				]
			},
			{
				path: 'my-base',
				name: 'my-base',
				meta: { requiresAuth: true, crm: true },
				component: () => import('@/views/crm/my-base')
			},
			{
				path: 'exchange-rates-archive',
				name: 'exchange-rates-archive',
				meta: { requiresAuth: true, crm: true },
				component: () => import('@/views/crm/exchange-rates-archive')
			}
		]
	}
]
