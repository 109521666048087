<template>
	<div class="app-select" :class="{ open: open }" v-click-outside="closeSelect">
		<label :for="name" class="app-label" v-if="label">
			{{ label }}:
			<sup v-if="required">*</sup>
		</label>
		<div class="app-select__control">
			<div class="app-select__slot" @click="open = !open">
				<div class="app-select__field" :tabindex="tabindex">
					<div class="selected">
						{{ options.find(el => el.id == value).name }}
					</div>
				</div>
				<div class="app-select__append-inner" >
					<v-icon icon="arrow-down" size="14" />
				</div>
				<div class="app-select__dropdown" v-if='open'>
					<ul class='app-select__dropdown--list'>
						<li class='app-select__dropdown--item'
							:class='{"active": option.id === value}'
							v-for="(option, i) of options"
							:key="i"
							@click.stop="open = false; $emit('input', option.id);"
						>
							{{ option.name }}
						</li>
					</ul>
				</div>
			</div>
			<div class="app-select__details">
				<div class="error--text" v-if="errorMessage">{{ errorMessage }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
			open: false
		}),
		props: {
			name: String,
			label: String,
			value: [String, Number, Object],
			errorMessage: String,
			required: {
				type: Boolean,
				default: false
			},
			options: {
				type: Array,
				required: true
			},
			tabindex: {
				type: Number,
				required: false,
				default: 0
			}
		},
		methods: {
			closeSelect() {
				this.open = false
			}
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables.scss";

	.app-select {
		font-size: 16px;
		font-weight: 400;
		max-width: 100%;
		margin-bottom: 6px;
		&.open {
			.app-select__append-inner .app-icon {
				transform: rotate(-90deg);
			}
		}
		&.valid {
			.app-select__slot {
				&:before {
					height: 3px;
				}
			}
		}
		&.invalid {
			.app-select__slot {
				border-color: rgba($red, .4);
			}
		}
		&__slot {
			padding: 0 15px;
			min-height: 45px;
			border: 1px solid rgba($black, 0.1);
			border-radius: $border-radius;
			cursor: pointer;
			position: relative;
			display: flex;
			align-items: center;
			transition: border-color $transition;
			&:hover {
				border-color: rgba($black, 0.3);
			}
			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				left: 0;
				height: 0;
				background-color: $green;
				transition: height $transition;
			}
		}
		&__field {
			display: flex;
			align-items: center;
			flex: 1 1 auto;
			position: relative;
		}
		&__append-inner {
			color: $pink;
			padding-left: 4px;
			.app-icon {
				transition: transform $transition;
			}
			&--action {
				cursor: pointer;
			}
		}
		&__details {
			font-size: 12px;
			font-weight: 400;
			line-height: 17px;
			min-height: 17px;
		}
		&__dropdown {
			z-index: 2;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			margin-top: 2px;
			background-color: $white;
			box-shadow: 0px 4px 30px rgba(86, 86, 86, 0.2);
			border-radius: 10px;
			overflow-x: hidden;
			overflow-y: auto;
			max-height: 260px;
			&--item {
				padding: 12px 20px;
				font-weight: 400;
				transition: background-color $transition;
				&:hover {
					background-color: $azure;
					color: $green;
					font-weight: 700;
				}
				&.active {
					color: $black;
					background-color: $azure;
					font-weight: 700;
					cursor: default;
				}
			}
		}
	}
	.app-label {
		display: block;
		font-size: 14px;
		line-height: 1.4;
		margin-bottom: 5px;
		sup {
			color: $green;
			font-size: inherit;
		}
	}
</style>
