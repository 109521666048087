<template>
  <div ref="a-profile" @keydown.esc="closeDialog" tabindex="0">
    <transition name="translate-right">
      <div class="a-profile" v-if="value">
        <div class="a-profile-wrap" v-click-outside="closeDialog">
          <div class="a-profile-header-fixed" :class="{ isFixed: isFixed }">
            <div class="row align-items-center">
              <div class="col">
                <div class="a-profile-header-fixed-avatar">
                  <a-user :user="user" />
                  <span class="a-profile-info-avatar--dots">
                    <span class="a-profile-info-avatar--dot"></span>
                    <span class="a-profile-info-avatar--dot"></span>
                    <span class="a-profile-info-avatar--dot"></span>
                  </span>
                </div>
              </div>
              <div class="col-auto">
                <div @click="closeDialog">
                  <v-icon icon="cross" size="17" />
                </div>
              </div>
            </div>
          </div>

          <div class="a-profile-info">
            <div
              class="a-profile-info-avatar"
              :style="`background-color: ${
                user.avatar_color ? user.avatar_color : null
              }`"
            >
              <template v-if="!loading">
                <img
                  class="a-profile-info-avatar--image"
                  :src="user.avatar"
                  v-if="user.avatar"
                />
                <img
                  class="a-profile-info-avatar--image"
                  :src="user.logo"
                  v-else-if="user.logo"
                />
                <p class="a-profile-info-avatar--abbr" v-else>
                  <span v-if="user.name">{{ user.name.slice(0, 1) }}</span>
                  <span v-if="user.surname">{{
                    user.surname.slice(0, 1)
                  }}</span>
                </p>
              </template>
              <span class="a-profile-info-avatar--dots">
                <span class="a-profile-info-avatar--dot"></span>
                <span class="a-profile-info-avatar--dot"></span>
                <span class="a-profile-info-avatar--dot"></span>
              </span>
            </div>

            <div class="row align-items-center a-profile-info-row">
              <div class="col">
                <p class="a-heading-1 a-profile-info-title" v-if="loading">
                  <span
                    class="skeleton"
                    :style="`width: 150px; height: 30px`"
                  />
                </p>
                <template v-else>
                  <p class="a-heading-1 a-profile-info-title" v-if="showId">
                    #{{ user.id }}
                  </p>
                  <p class="a-heading-1 a-profile-info-title">
                    {{ user.name }} {{ user.surname }}
                  </p>
                </template>
              </div>
              <div class="col-auto">
                <slot name="action" />
              </div>
            </div>

            <div class="row a-profile-info-contacts" v-if="loading">
              <div class="col-auto">
                <p class="a-profile-info-text">
                  <span
                    class="skeleton"
                    :style="`width: 100px; height: 15px`"
                  />
                </p>
              </div>
              <div class="col-auto">
                <p class="a-profile-info-text">
                  <span
                    class="skeleton"
                    :style="`width: 100px; height: 15px`"
                  />
                </p>
              </div>
            </div>
            <div class="row a-profile-info-contacts" v-else>
              <div class="col-auto" v-if="user.email">
                <p class="a-profile-info-text">{{ user.email }}</p>
              </div>
              <div class="col-auto" v-if="user.phone">
                <p class="a-profile-info-text">
                  {{ user.phone | VMask('+### (##) ### ## ##') }}
                </p>
              </div>
            </div>
          </div>
          <slot name="setting" />

          <slot name="content" />

          <div class="a-profile-button-close" @click="closeDialog">
            <v-icon icon="cross" size="17" />
          </div>
        </div>
      </div>
    </transition>
    <div class="backdrop" v-if="value"></div>
  </div>
</template>

<script>
import masker from 'vue-the-mask/src/masker';
import tokens from 'vue-the-mask/src/tokens';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default {
  data: () => ({
    isFixed: false,
  }),
  filters: {
    VMask: (value, mask) => masker(value, mask, true, tokens),
  },
  props: ['value', 'user', 'loading', 'double', 'showId'],
  methods: {
    _isFixed() {
      this.container = document.querySelector('.a-profile-wrap');

      this.container.addEventListener(
        'scroll',
        () => {
          let self = this;
          // self.isFixed = self.container.scrollTop >= document.querySelector('.a-profile-info').offsetHeight;
          self.isFixed = self.container.scrollTop >= 100;
        },
        false
      );
    },
    closeDialog() {
      if (this.double) return;
      this.$emit('input', false);
    },
  },
  watch: {
    value() {
      setTimeout(() => {
        const hideEl = document.querySelector('.a-profile-wrap');
        this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl);

        this._isFixed();
      }, 150);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/global/variables';
@import '@/styles/global/mixins';

.a-profile {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  &-wrap {
    background-color: $white;
    width: 100%;
    height: 100%;
    padding: 30px 15px;
    max-width: 832px * 0.8;
    margin-left: auto;
    overflow-x: hidden;
    overflow-y: auto;
    @include minw($grid-breakpoints-xs) {
      padding: 60px * 0.8 55px * 0.8;
    }
    @include minw($grid-breakpoints-md) {
      max-width: 832px * 0.9;
      padding: 60px * 0.9 55px * 0.9;
    }
    @include minw($grid-breakpoints-lg) {
      max-width: 832px;
      padding: 60px 55px;
    }
  }
  &-info {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba($black, 0.1);
    @include minw($grid-breakpoints-xs) {
      padding-bottom: 25px;
    }
    &-row {
      margin-bottom: 35px;
      @include minw($grid-breakpoints-xs) {
        margin-bottom: 20px;
      }
    }
    &-avatar {
      width: 82px;
      height: 82px;
      background-color: $green-persian;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      @include minw($grid-breakpoints-xs) {
        width: 115px;
        height: 115px;
      }
      &--image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
      }
      &--abbr {
        font-size: 32px;
        font-weight: 400;
        color: $white;
        text-transform: uppercase;
        @include minw($grid-breakpoints-xs) {
          font-size: 48px;
        }
      }
      &--dots {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--dot {
        position: absolute;
        border-radius: 50%;
        box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
        &:nth-child(1) {
          width: 149px;
          height: 149px;
          @include minw($grid-breakpoints-xs) {
            width: 189px;
            height: 189px;
          }
        }
        &:nth-child(2) {
          width: 239px;
          height: 239px;
          @include minw($grid-breakpoints-xs) {
            width: 299px;
            height: 299px;
          }
        }
        &:nth-child(3) {
          width: 321px;
          height: 321px;
          @include minw($grid-breakpoints-xs) {
            width: 411px;
            height: 411px;
          }
        }
      }
    }
    &-contacts {
      @include maxw($grid-breakpoints-xs - 1) {
        flex-direction: column;
      }
    }
    &-text {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    &-btn {
      color: $green-persian;
      cursor: pointer;
      transition: color $transition;
      &:hover {
        color: $green-active;
      }
    }
  }
  &-button {
    &-close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      &:hover {
        color: $green-active;
      }
      & .app-icon {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  &-content {
    &-block {
      padding: 25px 0;
      &:not(:last-child) {
        border-bottom: 1px solid rgba($black, 0.1);
      }
    }
    &-row {
      margin-bottom: 5px;
    }
    &-descr {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.5;
      color: rgba($black, 0.5);
      margin-top: 5px;
    }
  }

  &-message {
    margin-top: 20px;
    @include minw($grid-breakpoints-xs) {
      margin-top: 50px;
    }
    &-title {
      margin-bottom: 20px;
    }
    &-textarea {
      margin-bottom: 20px;
    }
    &-btn {
      @include minw($grid-breakpoints-xs) {
        max-width: 125px;
      }
    }
  }
  &-header-fixed {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    background-color: $white;
    border-bottom: 1px solid rgba($black, 0.1);
    width: 100%;
    max-width: 832px * 0.8;
    overflow: hidden;
    padding: 12px 15px;
    transition: transform $transition;
    transform: translateY(-100%);
    @include minw($grid-breakpoints-xs) {
      padding: 20px 30px;
    }
    @include minw($grid-breakpoints-md) {
      max-width: 832px * 0.9;
    }
    @include minw($grid-breakpoints-lg) {
      max-width: 832px;
    }
    &.isFixed {
      transform: translateY(0);
    }
    &-avatar {
      display: flex;
      align-items: center;
    }
  }
}
</style>
