<template>
  <a-dropdown
    class="a-select"
    :class="{
      'a-select-xxsmall': xxsmall,
      'a-select-xsmall': xsmall,
      'a-select-small': small,
    }"
    class-dropdown="a-select__dropdown"
    :invalid="errors"
  >
    <template v-slot:label>
      <label class="app-label" v-if="label">
        {{ label }}:
        <sup v-if="required">*</sup>
      </label>
    </template>
    <template v-slot:field>
      <template v-if="hasSlot('field')">
        <div class="a-select__field">
          <slot name="field" />
        </div>
      </template>
      <template v-else>
        <div class="a-select__field">
          <div class="selected" v-if="innerValue && innerValue.name">
            {{ innerValue.name }}
          </div>
          <div class="placeholder" v-else>{{ placeholder }}</div>
        </div>
      </template>
      <slot name="count" />
      <div class="a-select__append-inner" v-if="appendIcon">
        <v-icon :icon="appendIcon" size="20" />
      </div>
    </template>
    <template v-slot:dropdown="{ closeSelect }">
      <ul class="a-select__dropdown--list">
        <li
          class="a-select__dropdown--item"
          :class="{
            active:
              option[itemValue] === (innerValue ? innerValue[itemValue] : null),
            disabled: checkDisabled(option),
          }"
          v-for="(option, i) of options"
          :key="i"
          @click.stop="$emit('input', option[itemValue]), closeSelect()"
        >
          <template v-if="hasSlot('dropdown-list-item')">
            <slot name="dropdown-list-item" :option="option" />
          </template>
          <template v-else>
            <span>{{ option.name }}</span>
          </template>
          <v-icon
            icon="check"
            size="12"
            class="a-select__dropdown--check ml-auto"
          />
        </li>
      </ul>
    </template>
  </a-dropdown>
</template>

<script>
export default {
  props: {
    value: [String, Number, Object],
    'item-value': {
      type: String,
      default: 'id',
    },
    options: Array,
    placeholder: {
      type: String,
    },
    'append-icon': {
      type: [String, Boolean],
      default: 'arrow-down',
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    xxsmall: {
      type: Boolean,
      default: false,
    },
    errors: [Boolean, Array],
    label: String,
    required: {
      type: Boolean,
      default: false,
    },
    isDisabledSegments: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue() {
      return (
        this.options.find(el => el[this.itemValue] === this.value) ??
        this.$emit('input', null)
      );
    },
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    checkDisabled(option) {
      if (this.isDisabledSegments) {
        return option.seatMap.length === 0;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/global/variables.scss';
@import '@/styles/global/mixins';

.a-select {
  &__field {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    .selected,
    .placeholder {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .placeholder {
      color: rgba($black, 0.4);
    }
  }
  &__prepend-inner {
    margin-right: 15px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__append-inner {
    padding-left: 4px;
    transition: transform $transition;
    .app-icon {
      color: $green;
      width: 20px !important;
      width: 20px !important;
    }
    &--action {
      display: flex;
      align-items: center;
      cursor: pointer;
      .app-icon-loading {
        cursor: default;
        margin-right: 12px;
        animation: rotating 0.6s linear infinite;
        svg {
          fill: none;
          path {
            stroke: currentColor;
            stroke-width: 2px;
          }
          circle {
            stroke: currentColor;
            stroke-width: 2px;
            stroke-opacity: 0.25;
          }
        }
      }
      &-clear {
        .app-icon {
          color: rgba($black, 0.5);
          &:hover {
            color: rgba($black, 0.8);
          }
        }
      }
    }
  }
  &__dropdown {
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 300px;
    &--item {
      padding: 15px 20px;
      font-weight: 500;
      display: flex;
      align-items: center;
      transition: background-color $transition;
      cursor: pointer;
      &:hover {
        background-color: $cyan-light;
        color: rgba($green-persian, 0.8);
      }
      &.active {
        color: $white;
        background-color: $green-persian;
        cursor: default;
        .a-select__dropdown--check {
          display: block;
        }
      }
      &:not(.active) {
        .a-select__dropdown--item-icon-flight {
          color: rgba($green-persian, 0.8);
        }
      }
    }
    &--check {
      flex-shrink: 0;
      display: none;
    }
  }
  &-outline {
    .a-dropdown__slot {
      border-radius: 30px;
      overflow: hidden;
      &:before {
        content: normal;
      }
    }
    .a-dropdown__body {
      border-radius: $border-radius;
    }
  }
  &-small {
    & .open .a-dropdown__slot {
      border-radius: $border-radius $border-radius 0 0;
    }
    .a-dropdown__slot {
      padding: 0 12px;
      min-height: 45px;
      overflow: hidden;
      &:hover {
        border-color: rgba($black, 0.1);
      }
    }
    .app-icon {
      width: 20px !important;
      height: 20px !important;
    }
    .a-select-search__input.withIcon {
      padding-left: 43px;
    }
  }
  &-xsmall,
  &-xxsmall {
    font-size: 14px;
    & .open .a-dropdown__slot {
      border-radius: $border-radius $border-radius 0 0;
    }
    .a-dropdown__slot {
      padding: 0 12px;
      min-height: 40px;
      overflow: hidden;
      // &:before {
      // 	content: normal;
      // }
      &:hover {
        border-color: rgba($black, 0.1);
      }
    }
    .app-icon {
      width: 20px !important;
      height: 20px !important;
    }
    .a-select__dropdown {
      max-height: 130px;
      box-shadow: none;
      margin-top: -1px;
      right: 0;
      left: 0;
      border: 1px solid rgba($black, 0.1);
      &--item {
        padding: 2px 15px;
      }
    }
  }
  &-xsmall {
    .a-dropdown__slot {
      min-height: 45px;
    }
    .a-select__dropdown {
      &--item {
        padding: 6px 15px;
      }
    }
  }
  &-count {
    .a-dropdown__slot {
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
      @include minw($grid-breakpoints-xs) {
        padding: 0 30px;
      }
    }
    .a-select__field {
      font-size: 15px;
      font-weight: 400;
      @include minw($grid-breakpoints-xs) {
        font-size: 18px;
        font-weight: 700;
      }
    }
    &-text {
      font-size: 12px;
      font-weight: 400;
      color: rgba($black, 0.5);
      margin-right: 10px;
      white-space: nowrap;
      @include minw($grid-breakpoints-xs) {
        font-size: 16px;
        margin-right: 16px;
      }
    }
  }
}
</style>
