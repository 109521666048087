import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/locales/en';

Vue.use(VueI18n);

export const i18n = new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE,
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
	messages: { en }
});
