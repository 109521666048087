<template>
	<div class="v-pagination-container">
		<div class="row align-items-center">
			<div class="col">
				<p class='v-pagination-result' :class='{[size]: size}' v-if='!hideCount'>{{(page - 1) * perPage + 1}}-{{page * perPage > total ? total : page * perPage }} {{$t('account.with')}} {{total}} {{$t('account.results')}}</p>
			</div>
			<div class="col-auto">
				<ul class='v-pagination' :class='{[size]: size}'>
					<li v-if='page !== 1'>
						<button type="button" class="v-pagination__navigation v-pagination__prev" @click='$emit("update-pagination", page - 1), scrollTo()'>
							<v-icon icon='arrow-down' :size='size === "small" ? 10 : 13' />
						</button>
					</li>
					<template v-if='totalPage !== 1'>
						<li v-for='(item, i) in abridge(totalPage)' :key='i'>
							<template v-if='isNaN(item)'>
								<div>
									<span type="button" class="v-pagination__more">...</span>
								</div>
							</template>
							<template v-else>
								<button type="button" class="v-pagination__item" :class='{"v-pagination__item--active": item === page}'
									@click='$emit("update-pagination", item), scrollTo()'
								>
									{{item}}
								</button>
							</template>
						</li>
					</template>
					<li v-if='totalPage !== page'>
						<button type="button" class="v-pagination__navigation v-pagination__next" @click='$emit("update-pagination", page + 1), scrollTo()'>
							<v-icon icon='arrow-down' :size='size === "small" ? 10 : 13' />
						</button>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["total", "page", "perPage", "hideCount", "size"],
		computed: {
			totalPage() {
				return Math.ceil(this.total / this.perPage)
			}
		},
		methods: {
      abridge: function(pages) {
        var _distance, _edge, _pages, i, idx, ref;
        _pages = {};
        _edge = 2;
        for (idx = i = 1, ref = pages; (1 <= ref ? i <= ref : i >= ref); idx = 1 <= ref ? ++i : --i) {
          _distance = Math.abs(parseInt(idx) - this.page);
          if (idx === 1 || idx === pages || _distance < _edge) {
            _pages[idx] = idx;
          } else {
            if (_distance === _edge) {
              _pages[idx] = "...";
            }
          }
        }
        return _pages;
      },
			scrollTo() {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.v-pagination {
		display: inline-flex;
		align-items: center;
		margin-top: 15px;
		@include minw($grid-breakpoints-md) {
			margin-top: 10px;
		}
		&-container {
			width: 100%;
			.row {
				flex-direction: column;
				@include minw($grid-breakpoints-md) {
					flex-direction: row;
				}
			}
		}
		&-result {
			font-size: 15px;
			font-weight: 400;
			@include minw($grid-breakpoints-md) {
				font-size: 18px;
			}
			&.small {
				@include minw($grid-breakpoints-md) {
					font-size: 12px;
				}
			}
		}
		&__item,
		&__navigation {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 45px;
			height: 45px;
			margin: 4px;
			font-weight: 400;
			font-size: 15px;
			color: $green-persian;
			border: 1px solid rgba($green-persian, .2);
			border-radius: 50%;
			transition: background-color $transition;
			@include minw($grid-breakpoints-xxs) {
				width: 50px;
				height: 50px;
			}
			@include minw($grid-breakpoints-md) {
				width: 55px;
				height: 55px;
				font-size: 18px;
				margin: 5px;
			}
			&:hover,
			&--active {
				color: $white;
				background-color: $green-persian;
			}
		}
		&__more {
			font-size: 15px;
			font-weight: 400;
			margin-left: 8px;
			margin-right: 8px;
			color: $green-persian;
			@include minw($grid-breakpoints-md) {
				font-size: 18px;
				margin-left: 10px;
				margin-right: 10px;
			}
		}
		&__prev {
			transform: rotate(90deg);
		}
		&__next {
			transform: rotate(-90deg);
		}
		&.small {
			.v-pagination {
				&__item,
				&__navigation {
					@include minw($grid-breakpoints-md) {
						width: 36px;
						height: 36px;
						font-size: 13px;
					}
				}
			}
		}
	}
</style>
