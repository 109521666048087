<template>
  <component
    :is="type"
    :to="to"
    type="button"
    :class="classes"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <div class="app-btn__content">
      <slot />
    </div>
    <div class="app-btn__loader" v-if="loading">
      <v-icon icon="loading" size="24" style="margin-right: 0" />
    </div>
  </component>
</template>

<script>
export default {
  props: {
    to: { required: false },
    outline: Boolean,
    block: Boolean,
    disabled: Boolean,
    loading: Boolean,
    small: Boolean,
    xsmall: Boolean,
    xxsmall: Boolean,
    color: String,
  },
  computed: {
    type() {
      return this.to ? 'router-link' : 'button';
    },
    classes() {
      return {
        'app-btn': true,
        'app-btn--outline': this.outline,
        'app-btn--block': this.block,
        'app-btn--disabled': this.disabled,
        'app-btn--small': this.small,
        'app-btn--xsmall': this.xsmall,
        'app-btn--xxsmall': this.xxsmall,
        'app-btn--loading': this.loading,
        [this.color && this.outline ? this.color + '--text' : this.color]:
          this.color,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/global/variables.scss';
@import '@/styles/global/mixins.scss';

.app-btn {
  font-size: 16px;
  font-weight: 700;
  border-radius: $border-radius;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 16px;
  height: 57px;
  min-width: 255px;
  overflow: hidden;
  transition: background-color $transition;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: inherit;
    background-color: currentColor;
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition;
  }
  &:hover {
    &.white {
      color: $green-active;
    }
    &.green,
    &.green-persian,
    &.orange {
      &:not(.app-btn--disabled) {
        background-color: $green-active !important;
      }
      &:before {
        opacity: 0;
      }
    }
    &.green:not(.app-btn--disabled) {
      background-color: $green-active !important;
    }
    &.orange:not(.app-btn--disabled) {
      background-color: #cc7a00 !important;
    }
  }
  &.red {
    background-color: rgba($red, 0.8) !important;
    &:hover {
      background-color: rgba($red, 1) !important;
    }
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &--outline {
    border: 1px solid;
    &:hover {
      .app-btn__content {
        color: $black;
      }
      &.green--text,
      &.green-persian--text {
        .app-btn__content {
          color: $white;
        }
      }
      &:before {
        opacity: 1;
      }
    }
  }
  &--block {
    width: 100% !important;
    min-width: 100% !important;
  }
  &--disabled {
    pointer-events: none;
    color: rgba($black, 0.26) !important;
    background-color: rgba($black, 0.12) !important;
  }
  &--loading {
    pointer-events: none;
    .app-btn__content {
      opacity: 0;
    }
    .app-btn__loader .app-icon {
      animation: rotating 0.6s linear infinite;
      svg {
        fill: none;
        path {
          stroke: currentColor;
          stroke-width: 2px;
        }
        circle {
          stroke: currentColor;
          stroke-width: 2px;
          stroke-opacity: 0.25;
        }
      }
    }
  }
  &--small {
    height: 57px;
    min-width: auto;
    padding: 0 50px;
  }
  &--xsmall {
    height: 50px;
    min-width: auto;
  }
  &--xxsmall {
    height: 47px;
    min-width: auto;
  }
  .app-icon {
    flex-shrink: 0;
    margin-right: 10px;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
