<template>
	<v-dialog :value='value' :width='800' class='a-dialog-success-wrapper' class-content='border-radius a-dialog-success' @close-dialog="$emit('close-dialog')">
		<slot />
		<div class='a-dialog-success-content'>
			<div class='a-dialog-success-icon'>
				<v-icon icon='check' size='21' />
			</div>
			<p class='a-dialog-success-title'>{{title}}</p>
			<p class='a-dialog-success-text'>{{text}}</p>
			<div class='a-dialog-success-action'>
				<v-button block small color='green white--text' @click.stop="$emit('close-dialog')">{{$t('account.buttons.close')}}</v-button>
			</div>
		</div>
	</v-dialog>
</template>

<script>
	export default {
		props: ['value', 'title', 'text']
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-dialog-success {
		&-wrapper {
			.app-popup {
				z-index: 20;
			}
			.backdrop {
				z-index: 15;
			}
		}
		@include maxw( $grid-breakpoints-xs - 1 ) {
			max-width: 100% !important;
			border-radius: 0 !important;
			display: flex;
			align-items: center;
		}
		&-content {
			text-align: center;
			width: 100%;
		}
		&-icon {
			color: $green-persian;
			border: 3px solid $green-persian;
			border-radius: 50%;
			width: 46px;
			height: 46px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 20px auto;
			@include minw( $grid-breakpoints-xs ) {
				margin: 0 auto 30px;
			}
		}
		&-title {
			font-size: 20px;
			font-weight: 400;
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-xs ) {
				font-weight: 700;
				margin-bottom: 15px;
			}
		}
		&-text {
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
			max-width: 550px;
			margin: auto;
			margin-bottom: 65px;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 16px;
				margin-bottom: 40px;
			}
		}
		&-action {
			@include minw( $grid-breakpoints-xs ) {
				width: 167px;
				margin: auto;
			}
		}
	}
</style>
