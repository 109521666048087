<template>
	<span
		class="app-icon"
		:style="`width: ${size}px; height: ${size}px;`"
		v-html="require(`!html-loader!@/assets/svg/${icon}.svg`)"
	/>
</template>

<script>
	export default {
		props: {
			icon: { required: true },
			size: {
				type: [String, Number],
				default: 24
			}
		}
	};
</script>

<style lang="scss">
	.app-icon {
		display: block;
	}
</style>
