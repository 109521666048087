<template>
  <div
    class="app-input"
    :class="{
      'app-textarea': type === 'textarea',
      isLarge: isLarge,
      invalid: invalid,
      valid: isFocus,
    }"
  >
    <label :for="`${textarea}-${name}`" class="app-label" v-if="label">
      {{ label }}:
      <sup v-if="required">*</sup>
    </label>
    <div class="app-input__control">
      <div class="app-input__slot">
        <div class="app-input__prepend-inner" v-if="iconPrepend">
          <v-icon :icon="iconPrepend" size="24" />
        </div>
        <div class="app-input__field">
          <template v-if="type === 'textarea'">
            <textarea
              :id="`textarea-${name}`"
              :type="type"
              :placeholder="placeholder"
              :value="value"
              @input="changeValue"
              @blur="changeValue"
              :rows="rows"
            />
          </template>
          <template v-else>
            <input
              :id="`input-${name}`"
              :type="type"
              :placeholder="placeholder"
              v-model="innerValue"
              @input="changeValue"
              @blur="changeBlur"
              @keypress="inputRegex"
              autocomplete="off"
              v-mask="mask"
              @focus="isBorderFocus ? (isFocus = true) : false"
            />
          </template>
        </div>
        <div
          class="app-input__append-inner"
          :class="{ 'app-input__append-inner--action': iconAction }"
          v-if="icon"
          @click="iconAction"
        >
          <v-icon :icon="icon" :size="iconSize" />
        </div>
      </div>
      <div class="app-input__details" v-if="!hideDetails">
        <div class="error--text" v-if="errorMessage">{{ errorMessage }}</div>
        <div v-if="hint">{{ hint }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  data: () => ({
    innerValue: null,
    isFocus: false,
  }),
  directives: {
    mask: (el, binding) => {
      if (!binding.value) return;
      mask(el, binding);
    },
  },
  props: {
    name: String,
    value: String,
    label: String,
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    errorMessage: String,
    icon: String,
    iconSize: {
      type: Number,
      default: 26,
    },
    iconPrepend: String,
    iconAction: Function,
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: [Boolean, Number],
      default: false,
    },
    mask: {
      type: [String, Boolean],
      default: false,
    },
    'hide-details': {
      type: Boolean,
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    hint: String,
    invalid: [Boolean, Array],
    isRegex: {
      type: Boolean,
      default: false,
    },
    regex: RegExp,
    isUpperCase: {
      type: Boolean,
      default: false,
    },
    isBorderFocus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textarea() {
      return this.type === 'textarea' ? 'textarea' : 'input';
    },
  },
  created() {
    this.innerValue = this.value;
  },
  methods: {
    changeValue($event) {
      let v = $event.target.value;
      this.innerValue = this.isUpperCase ? v.toUpperCase() : v;
      this.mask
        ? this.$emit('input', this.phoneMask(v))
        : this.$emit('input', this.isUpperCase ? v.toUpperCase() : v);
    },
    changeBlur($event) {
      this.changeValue($event);
      this.isBorderFocus ? (this.isFocus = false) : false;
    },
    phoneMask(value) {
      return value.replace(/[\s+-]+/g, '');
    },
    inputRegex(e) {
      if (this.isRegex) {
        let char = String.fromCharCode(e.keyCode);
        if (this.regex.test(char)) return true;
        else e.preventDefault();
      }
    },
  },
  watch: {
    value() {
      this.innerValue = this.value;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/global/variables.scss';

.app-input {
  font-size: 16px;
  font-weight: 400;
  max-width: 100%;
  margin-bottom: 6px;
  &.valid {
    .app-input__slot:before {
      height: 3px;
    }
  }
  &.invalid {
    .app-input__slot:before {
      height: 3px;
      background-color: $red;
    }
  }
  &__slot {
    padding: 0 15px;
    min-height: 45px;
    border: 1px solid rgba($black, 0.1);
    border-radius: $border-radius;
    cursor: text;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: border-color $transition;
    &:hover {
      border-color: rgba($black, 0.3);
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 0;
      z-index: 1;
      background-color: $green;
      transition: height $transition;
    }
  }
  &.isLarge {
    .app-input {
      &__slot {
        min-height: 62px;
      }
    }
  }
  &__field {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    position: relative;
    input,
    textarea {
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      max-width: 100%;
      min-width: 0;
      width: 100%;
      padding: 10px 0;
      box-sizing: border-box;
      &::placeholder {
        opacity: 1;
        color: rgba($black, 0.4);
      }
      &:focus::placeholder {
        opacity: 0;
        transform: translateX(-100%);
        transition: transform $transition, opacity $transition;
      }
      &:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 63px $white;
        -webkit-text-fill-color: $black;
      }
    }
  }
  &__append-inner {
    color: $pink;
    padding-left: 4px;
    &--action {
      cursor: pointer;
    }
  }
  &__prepend-inner {
    padding-right: 15px;
    &--action {
      cursor: pointer;
    }
  }
  &__details {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    min-height: 16px;
    color: rgba($black, 0.5);
    margin-top: 2px;
  }
}
.app-label {
  display: block;
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 5px;
  sup {
    color: $green;
    font-size: inherit;
  }
}
.app-textarea {
  .app-input__slot {
    padding-right: 0;
  }
  textarea {
    padding-right: 15px;
    resize: vertical;
  }
}
</style>
