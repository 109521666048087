import '@/assets/fonts/stylesheet.css';
import '@/styles/main.scss';
import Vue from 'vue';
import App from './App.vue';
import router from "@/router";
import store from '@/store';
import { i18n } from "@/plugins/i18n";
import "@/plugins/index";
import "@/plugins/components";

import { Trans } from '@/plugins/Translation';
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

import helpers from '@/helpers';
Vue.prototype.$helpers = helpers;

import VTooltip from 'v-tooltip';
Vue.use(VTooltip)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

const token_type = window.localStorage.getItem('token_type');
const access_token = window.localStorage.getItem('access_token');

if (token_type && access_token) {
	Vue.prototype.$http.defaults.headers.common['Authorization'] = token_type + ' ' + access_token
}

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app');
