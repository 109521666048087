import moment from 'moment';
import { i18n } from '@/plugins/i18n'

export const DateAndTime = {
	time(minute) {
		let time = Number(minute) * 60;
		let d = Math.floor(time / (3600 * 24));
		let h = Math.floor(time % (3600 * 24) / 3600);
		let m = Math.floor(time % 3600 / 60);
		let s = Math.floor(time % 60);

		return { d, h, m, s }
	},
	timeName(t) {
		let { d, h, m, s } = this.time(t);

		let days = d > 0 ? d + `${i18n.t("account.shortTime.d")} ` : "";
		let hours = h > 0 ? h + `${i18n.t("account.shortTime.h")} ` : "";
		let minutes = m > 0 ? m + `${i18n.t("account.shortTime.m")} ` : "";
		let seconds = s > 0 ? s + `${i18n.t("account.shortTime.s")} ` : "";

		return days + hours + minutes + seconds;
	},
	diffDays(_start, _end) {
		let start = moment(_start).format("YYYY-MM-DD");
		let end = moment(_end).format("YYYY-MM-DD");
		return moment(end).diff(moment(start), 'days')
	}
}
