import { render, staticRenderFns } from "./VAlert.vue?vue&type=template&id=e59a8470&"
import script from "./VAlert.vue?vue&type=script&lang=js&"
export * from "./VAlert.vue?vue&type=script&lang=js&"
import style0 from "./VAlert.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports