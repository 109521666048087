<template>
	<ul class="nav">
		<li class="nav__item" v-for="(item, i) in items" :key="i">
			<router-link
				class="nav__link"
				:to="$i18nRoute({ name: item.link })"
				exact
				>{{ item.name }}</router-link
			>
		</li>
	</ul>
</template>

<script>
	export default {
		props: ["items"]
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.nav {
		display: flex;
		flex-wrap: wrap;
		&__item {
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
		&__link {
			font-size: 16px;
			font-weight: 400;
			color: $white;
			display: block;
			text-decoration: none;
			padding: 12px 15px;
			border-radius: 5px;
			@include minw( $grid-breakpoints-sm ) {
				&:hover {
					background-color: rgba($white, 0.25);
					transition: background-color $transition;
				}
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 12px 25px;
			}
		}
	}
</style>
