const state = {
	isActive: false,
	status: 'success',
	content: {
		title: null,
		text: null
	},
	errors: null
}

const mutations = {
	successMessage(state, payload) {
		state.isActive = true;
		state.status = 'success';
		state.content = {title: payload.title, text: payload.text};
		state.errors = null;
	},
	errorMessage(state, payload) {
		state.isActive = true;
		state.status = payload.status ? payload.status : "error";
		state.content = {title: payload.title, text: payload.text};
		state.errors = payload.errors;
	},
	clearMessage(state) {
		state.isActive = false;
		state.status = 'success';
		state.content = {
			title: null,
			text: null
		};
		state.errors = null;
	}
}

export default {
  namespaced: true,
  state,
  mutations
}
