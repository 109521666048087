<template>
	<transition name='translate-right'>
		<div class='v-snack' :class='position' v-if='isActive'>
			<div class="v-snack__wrapper">
				<div class="v-snack__icon">
					<div class='v-snack__icon-status' :class='notification.status === "success" ? "success--text" : notification.status'>
						<v-icon :icon='findIcon' :size='notification.status === "warning" ? 25 : 11' />
					</div>
				</div>
				<div class="v-snack__content">
					<template v-if='notification.errors'>
						<div class='v-snack__content-scroll'>
							<p class='v-snack__content-text' v-for="(item, i) in notification.errors" :key='i'>
								{{i}} - {{item}}
							</p>
						</div>
					</template>
					<template v-else>
						<p class='v-snack__content-title' v-if='notification.content.title'>{{notification.content.title}}</p>
						<p class='v-snack__content-text' v-if='notification.content.text'>{{notification.content.text}}</p>
					</template>
				</div>
				<div class="v-snack__action">
					<div class='v-snack__action-button' @click.stop="$store.commit('notification/clearMessage')">
						<v-icon icon='cross' size='12' />
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default {
		data: () => ({
			setTimeout: null
		}),
		props: {
			position: {
				type: String,
				default: 'top right'
			},
			interval: {
				type: Number,
				default: 5000
			}
		},
		computed: {
			notification() {
				return this.$store.state.notification;
			},
			isActive() {
				return this.notification.isActive;
			},
			findIcon() {
				switch (this.notification.status) {
					case 'error':
						return 'cross';
					case 'warning':
						return 'warning';
					default:
						return 'check';
				}
			}
		},
		created() {
			this.clearSnackbar();
		},
		methods: {
			clearSnackbar() {
				clearTimeout(this.setTimeout)

				if(!this.isActive) return;

				this.setTimeout = setTimeout(() => {
					this.$store.commit('notification/clearMessage')
				}, this.interval)
			}
		},
		watch: {
			isActive() {
				this.clearSnackbar();
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.v-snack {
		position: fixed;
		z-index: 1000;
		display: flex;
		justify-content: center;
		@include minw( $grid-breakpoints-xs ) {
			&.top {
				top: 40px;
				&.right {
					right: 30px;
				}
			}
		}
		@include maxw( $grid-breakpoints-xs - 1 ) {
			bottom: 70px;
			left: 0;
			right: 0;
		}
		&__wrapper {
			display: flex;
			align-items: stretch;
			background: $white;
			box-shadow: 0px 0px 30px rgba(86, 86, 86, 0.2);
			border-radius: $border-radius * 2;
			padding: 15px;
			max-width: 350px;
		}
		&__icon {
			display: flex;
			align-items: center;
			&-status {
				width: 25px;
				height: 25px;
				border-radius: 50%;
				margin-right: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $white;
				&.success--text {
					border: 2px solid $green-persian;
				}
			}
		}
		&__content {
			&-scroll {
				max-height: 120px;
				overflow-x: hidden;
				overflow-y: auto;
			}
			&-title {
				font-size: 16px;
				line-height: 23px;
				font-weight: 700;
				margin-bottom: 2px;
			}
			&-text {
				font-size: 14px;
				line-height: 20px;
				font-weight: 400;
				color: rgba($black, .5);
			}
		}
		&__action {
			margin-left: 30px;
			&-button {
				padding: 5px;
				cursor: pointer;
			}
		}
	}
</style>
