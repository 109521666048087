import Vue from 'vue';
import Vuex from 'vuex';

import { state } from './state';
import { mutations } from './mutations';
import { actions } from './actions';

import auth from './modules/auth';
import notification from './modules/notification';

Vue.use(Vuex)

export default new Vuex.Store({
	state,
	mutations,
	actions,
	modules: {
		auth,
		notification
	}
});
