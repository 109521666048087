import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import { i18n } from '@/plugins/i18n'

const buildAxios = () => {
	Vue.prototype.$http = axios;
	Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_GATEWAY_URL;

	Vue.prototype.$http.interceptors.response.use(
		res => res,
		error => {
			const token_type = window.localStorage.getItem('token_type');
			const access_token = window.localStorage.getItem('access_token');

			if(error.message === "Network Error" || error.response.status === 500) {
				store.commit('notification/errorMessage', {title: i18n.t("account.messages.errors.server_error.title"), text: i18n.t("account.messages.errors.server_error.text")})
			}
			if(error.response.status === 401 && token_type && access_token) {
				store.dispatch('auth/logout');
			}
			return Promise.reject(error)
		}
	)
}

export default buildAxios;
