<template>
  <div class="a-header-profile" v-if="$store.state.auth.user">
    <div class="a-header-profile__control">
      <div class="a-header-profile__slot" @click="openProfile = true">
        <div class="a-header-profile__field">
          <div class="a-header-profile--avatar">
            <img
              class="a-profile-info-avatar--image"
              :src="$store.state.auth.user.logo"
              v-if="$store.state.auth.user.logo"
            />
            <p class="a-header-profile--avatar--abbr" v-else>
              <span v-if="$store.state.auth.user.name">{{
                $store.state.auth.user.name.slice(0, 1)
              }}</span>
              <span v-if="$store.state.auth.user.surname">{{
                $store.state.auth.user.surname.slice(0, 1)
              }}</span>
            </p>
          </div>
          <div class="a-header-profile__append-inner">
            <v-icon icon="arrow-down" size="12" />
          </div>
          <div class="a-header-profile__info">
            <h3 class="a-header-profile__name">
              {{ $store.state.auth.user.name }}
              {{ $store.state.auth.user.surname }}
            </h3>
            <router-link
              class="a-header-profile__link"
              :to="$i18nRoute({ name: 'home' })"
              >{{ $t('account.view-profile') }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <a-profile
      v-model="openProfile"
      :user="$store.state.auth.user"
      :double="logout || success"
    >
      <template v-slot:action>
        <div
          class="a-profile-info-btn"
          @click="$emit('logoutProfile')"
          v-tooltip.top="$t('account.exit')"
        >
          <v-icon icon="exit-1" size="22" />
        </div>
      </template>
      <template v-slot:setting>
        <div class="a-profile-info-settings row">
          <div class="col-auto a-profile-info-settings-col">
            <a-select
              :label="$t('account.settings.interface_language')"
              :placeholder="$t('account.booking.content.choose')"
              :options="locales"
              item-value="code"
              v-model="language"
              @input="changeLanguage"
              small
              class="a-profile-info-settings-select"
            />
          </div>
          <div class="col-auto a-profile-info-settings-col">
            <a-select
              :label="$t('account.settings.currency_selection')"
              :placeholder="$t('account.booking.content.choose')"
              :options="exchange_rates"
              item-value="code"
              v-model="currency"
              @input="changeCurrency"
              small
              class="a-profile-info-settings-select"
            />
          </div>

          <div class="col-auto a-profile-info-settings-col">
            <p class="a-profile-info-settings-title">
              1 USD = {{ calculateRate('USD') }} UAH
            </p>
            <p class="a-profile-info-settings-title">
              1 EUR = {{ calculateRate('EUR') }} UAH
            </p>
            <router-link
              class="app-link green--text"
              :to="$i18nRoute({ name: 'exchange-rates-archive' })"
              >{{ $t('crm.exchange_rates_archive') }}</router-link
            >
          </div>
        </div>
				<div class="a-profile-info-settings row">
					<div class="col a-profile-info-settings-col">
						<mark-up></mark-up>
					</div>
				</div>
      </template>
<!--      <template v-slot:content>-->
<!--        <div class="a-profile-message">-->
<!--          <p class="a-sub-heading a-profile-message-title">-->
<!--            {{ $t('account.dialogs.header_profile.request_profile_editing') }}-->
<!--          </p>-->
<!--          <v-text-field-->
<!--            :placeholder="$t('account.form.placeholder.add_your_query')"-->
<!--            name="textarea"-->
<!--            type="textarea"-->
<!--            v-model="text"-->
<!--            :rows="5"-->
<!--            class="a-profile-message-textarea"-->
<!--          />-->
<!--          <div class="a-profile-message-btn">-->
<!--            <v-button-->
<!--              block-->
<!--              xxsmall-->
<!--              color="green white&#45;&#45;text"-->
<!--              @click="dataEditUser"-->
<!--              :loading="loading_btn"-->
<!--              :disabled="!text.length"-->
<!--              >{{ $t('account.buttons.send') }}</v-button-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
    </a-profile>

    <dialog-success
      :title="
        $t('account.messages.success.your_request_has_been_sent_successfully')
      "
      :text="
        $t(
          'account.messages.success.our_manager_will_contact_you_in_the_near_future'
        )
      "
      v-model="success"
      @close-dialog="success = false"
    />
  </div>
</template>

<script>
import AProfile from '@/components/account/AProfile';
import DialogSuccess from '@/views/account/home/components/DialogSuccess';
import { dataEditUser } from '@/services/request';
import LanguageAndCurrencyMixin from '@/mixins/language-and-currency-mixin';
import MarkUp from '@/views/account/settings/mark-up';

export default {
  data: () => ({
    text: '',
    openProfile: false,
    loading_btn: false,
    success: false,
  }),
  mixins: [LanguageAndCurrencyMixin],
  props: ['logout'],
  components: {
    AProfile,
    DialogSuccess,
		MarkUp
  },
  methods: {
    dataEditUser() {
      this.loading_btn = true;
      dataEditUser({ text: this.text })
        .then(() => (this.success = true), (this.text = ''))
        .finally(() => (this.loading_btn = false));
    },
    calculateRate(currency) {
      let UAH = this.$store.state.exchange_rates.find(el => {
        return el.code === 'UAH';
      });

      let rate = this.$store.state.exchange_rates.find(el => {
        return el.code === currency;
      });

      return UAH && rate
        ? Math.round(parseFloat(UAH.rate / rate.rate) * 100) / 100
        : 0;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/global/variables';
@import '@/styles/global/mixins';

.a-profile-info-settings {
  display: flex;
  align-items: center;
  padding-top: 30px;
  &-col {
    padding-bottom: 15px;
    @include maxw($grid-breakpoints-xs - 1) {
      flex: 1 1 100%;
    }
  }
  &-title {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  &-select {
    @include minw($grid-breakpoints-xs) {
      width: 150px;
    }
  }
}

.a-header-profile {
  &__control {
    cursor: pointer;
  }
  &--avatar {
    width: 42px;
    height: 42px;
    background: $green-persian;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &--abbr {
      color: $white;
      font-size: 18px;
      font-weight: 400;
    }
  }
  &__slot {
    position: relative;
    @include minw($grid-breakpoints-sm) {
      padding-right: 12px;
    }
  }
  &__field {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    position: relative;
  }
  &__append-inner {
    color: $green-persian;
    padding-left: 10px;
    display: none;
    @include minw($grid-breakpoints-sm) {
      display: inherit;
    }
    .app-icon {
      transition: transform $transition;
    }
  }
  &__info {
    padding-left: 8px;
    display: grid;
    @include minw($grid-breakpoints-sm) {
      display: none;
    }
  }
  &__name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 3px;
  }
  &__link {
    font-size: 14px;
    color: $green;
    font-weight: 700;
  }
}
</style>
