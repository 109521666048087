<template>
	<div ref='popup' @keydown.esc="closeDialog" tabindex='0'>
		<transition name="scale">
			<div class="app-popup" v-if='value'>
				<div class="app-popup__inner">
					<div class="app-popup__content" :class='classContent' :style='`max-width: ${width}px`' v-click-outside="closeDialog">
						<div class="app-popup__close" @click.stop="closeDialog" v-if='showCloseCross'>
							<v-icon icon="cross" size="17"></v-icon>
						</div>
						<slot />
					</div>
				</div>
			</div>
		</transition>
		<div class="backdrop" v-if='value'></div>
	</div>
</template>

<script>
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';

	export default {
		props: {
			value: {
				type: Boolean,
				default: false
			},
			showCloseCross: {
				type: Boolean,
				default: true
			},
			width: {
				type: Number,
				default: 890
			},
			classContent: {
				type: String
			}
		},
		methods: {
			is_touch_enabled() {
				return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
			},
			pageScroll() {
				// this.$refs.popup.focus();
				const hideEl = document.querySelector('.app-popup')
				this.value ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
			},
			closeDialog() {
				this.$emit('close-dialog');
			}
		},
		watch: {
			value() {
				this.is_touch_enabled() ? setTimeout(() => { this.pageScroll() }, 150) : this.pageScroll();
			}
		},
		beforeDestroy() {
			enablePageScroll()
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.app-popup {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 12;
		overflow-x: hidden;
		overflow-y: scroll;
		&__inner {
			width: 100%;
			min-height: 100%;
			display: grid;
			align-items: center;
			@include minw($grid-breakpoints-xs) {
				padding: 20px;
			}
		}
		&__content {
			background-color: $white;
			padding: 50px 20px;
			width: 100%;
			height: 100%;
			margin: auto;
			@include minw($grid-breakpoints-xs) {
				padding: 60px 50px;
				width: 100%;
				height: auto;
				box-shadow: 0px 0px 10px rgba(86, 86, 86, 0.1);
			}
			&.border-radius {
				border-radius: $border-radius * 2;
			}
		}
		&__close {
			position: absolute;
			right: 15px;
			top: 15px;
			cursor: pointer;
			z-index: 1;
			svg path {
				transition: fill $transition;
			}
			&:hover {
				svg path {
					fill: $green;
				}
			}
		}
	}
	.app-done-modal {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		max-width: 400px;
		margin: 0 auto;
		height: 100%;
		justify-content: center;
		.heading-3 {
			margin-bottom: 20px;
		}
		span {
			line-height: 1.5;
			padding-bottom: 50px;
			display: block;
		}
		&--registration {
			max-width: 760px;
		}
	}
	.scale {
		&-enter-active,
		&-leave-active {
			@media screen {
				overflow: hidden;
			}
			transition: opacity $transition, transform $transition;
		}
		&-enter,
		&-leave-to {
			opacity: 0;
			transform: scale(0);
		}
	}
</style>
