<template>
	<ul class="a-nav">
		<li class="a-nav__item" v-for="(item, i) in items" :key="i">
			<router-link
				class="a-nav__link"
				:class='{"disabled": !item.link}'
				:to="item.link ? $i18nRoute({ name: item.link }) : '#'"
			>{{ item.name }}</router-link>
		</li>
	</ul>
</template>

<script>
	export default {
		props: ["items"]
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-nav {
		display: flex;
		flex-wrap: wrap;
		margin-right: 40px * .6;
		@include minw( $grid-breakpoints-md ) {
			margin-right: 40px;
		}
		&__item {
			&:not(:last-child) {
				@include minw( $grid-breakpoints-md ) {
					margin-right: 6px;
				}
			}
		}
		&__link {
			font-size: 15px * .85;
			font-weight: 400;
			color: #444444;
			display: block;
			text-decoration: none;
			padding: 6px * .8 12px * .8;
			cursor: pointer;
			@include minw( $grid-breakpoints-sm ) {
				&:hover {
					color: $green-persian;
					transition: color $transition;
				}
			}
			@include minw( $grid-breakpoints-md ) {
				font-size: 15px;
				padding: 6px 12px;
			}
			&.router-link-active {
				color: $green-persian;
				font-weight: 700;
			}
			&.disabled {
				pointer-events: none;
				opacity: .3;
			}
		}
	}
</style>
