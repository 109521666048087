import Api from "@/services/api.js";

// Auth
export const registration = (data) => Api.post(`/auth/registration`, data);
export const login = (data) => Api.post(`/auth/login`, data);
export const logout = () => Api.get(`/auth/logout`);
export const user = () => Api.get(`/auth/user`);
export const reset = (data) => Api.post(`/password/reset`, data);

// Autocomplete
export const getAutocompleteTerminals = (request, cancelToken) => Api.get(`/autocomplete/terminals`, request, cancelToken);
export const getAutocompleteAirCompany = (request, cancelToken) => Api.get(`/autocomplete/air-company`, request, cancelToken);
export const getAutocompleteCharterAirCompany = (request, cancelToken) => Api.get(`/autocomplete/charter-air-company`, request, cancelToken);
export const getAutocompleteDestinationsOrHotels = (request, cancelToken) => Api.get(`/autocomplete/destinations-or-hotels`, request, cancelToken);
export const getAutocompleteDestinations = (request, cancelToken) => Api.get(`/autocomplete/destinations`, request, cancelToken);
export const getAutocompleteNationality = (request, cancelToken) => Api.get(`/autocomplete/nationality`, request, cancelToken);
export const getAutocompleteCountries = (request, cancelToken) => Api.get(`/autocomplete/countries`, request, cancelToken);
export const getAutocompleteLoyaltyPrograms = (request, cancelToken) => Api.get(`/autocomplete/loyalty-programs`, request, cancelToken);
export const getAutocompleteTourists = (request, cancelToken) => Api.get(`/autocomplete/tourists`, request, cancelToken);
export const getAutocompleteTransferPoint = (request, cancelToken) => Api.get(`/autocomplete/transfer-point`, request, cancelToken);
export const getAutocompleteCRMStatuses = (request, cancelToken) => Api.get(`/autocomplete/crm-order-statuses`, request, cancelToken);
export const getAutocompleteCRMTypes = (request, cancelToken) => Api.get(`/autocomplete/crm-order-types`, request, cancelToken);

// СharterFlight
export const charterFlightSearch = (data) => Api.post(`/charter-flight/search`, data);
export const getCharterFlightSearch = (storageId) => Api.get(`/charter-flight/search/fetch/${storageId}`);
export const charterFlightDetails = (data) => Api.post(`/charter-flight/details`, data);
export const getCharterFlightTerminals = (request) => Api.get(`/charter-flight/terminals`, request);
export const getCharterFlightSchedulers = (request) => Api.get(`/charter-flight/schedulers`, request);

// Comparison
export const addComparison = (data) => Api.post(`/comparison/add`, data);
export const comparisonCheckAvailability = (request) => Api.get(`/comparison/check-availability`, request);
export const getComparison = (comparisonId, request) => Api.get(`/comparison/${comparisonId}`, request);
export const clearComparison = (comparisonId) => Api.delete(`/comparison/${comparisonId}`);
export const deleteComparison = ({comparisonId, code}) => Api.delete(`/comparison/${comparisonId}/item/${code}`);
export const comparisonSend = (comparisonId, data) => Api.post(`/comparison/${comparisonId}/send`, data);

// ShareComparison
export const getShareComparison = (comparisonId) => Api.get(`/share-comparison/${comparisonId}`);

// Course
export const course = () => Api.get(`/course`);

// Faq
export const getFaqList = (request) => Api.get(`/faq`, request);
export const getFaq = (faqId) => Api.get(`/faq/${faqId}`);

// Feedback
export const feedback = (data) => Api.post(`/feedback`, data);

// Flight
export const flightSearch = (data) => Api.post(`/flight/search`, data);
export const getFlightSearch = (storageId) => Api.get(`/flight/search/fetch/${storageId}`);
export const flightDetails = (data) => Api.post(`/flight/details`, data);
export const flightSeatMap = (data) => Api.post(`/flight/seat-map`, data);

// Groupmaker
export const groupmaker = (data) => Api.post(`/groupmaker`, data);

// Help
export const help = (data) => Api.post(`/help`, data);

// Hotel
export const hotelSearch = (data) => Api.post(`/hotel/search`, data);
export const getHotelSearch = (storageId) => Api.get(`/hotel/search/fetch/${storageId}`);
export const getHotelDetails = (code) => Api.get(`/hotel/${code}`);
export const hotelDetails = (data) => Api.post(`/hotel/details`, data);

// HotelFavorite
export const hotelFavorite = () => Api.get(`/hotel/favorite`);
export const hotelFavoriteChecked = (code) => Api.get(`/hotel/favorite/checked/${code}`);
export const hotelFavoriteUnchecked = (code) => Api.get(`/hotel/favorite/unchecked/${code}`);

// Insurance
export const insuranceSearch = (data) => Api.post(`/insurance/search`, data);
export const getInsuranceSearch = (storageId) => Api.get(`/insurance/search/fetch/${storageId}`);

// LoyaltyProgram
export const getLoyaltyPrograms = (touristId) => Api.get(`/tourist/${touristId}/loyalty-program`);
export const addLoyaltyProgram = ({touristId, data}) => Api.post(`/tourist/${touristId}/loyalty-program`, data);
export const getLoyaltyProgram = (touristId, programId) => Api.get(`/tourist/${touristId}/loyalty-program/${programId}`);
export const updateLoyaltyProgram = ({touristId, programId, data}) => Api.put(`/tourist/${touristId}/loyalty-program/${programId}`, data);
export const deleteLoyaltyProgram = (touristId, programId) => Api.delete(`/tourist/${touristId}/loyalty-program/${programId}`);

// Order
export const getOrder = (orderId) => Api.get(`/order/${orderId}`);
export const deletedOrder = (orderId) => Api.delete(`/order/${orderId}`);
export const updateName = (orderId, data) => Api.put(`/order/${orderId}/update-name`, data);
export const updateInfo = ({orderId, data}) => Api.put(`/order/${orderId}/update-info`, data);
export const copyOrder = (orderId) => Api.get(`/order/${orderId}/copy`);
export const toBook = (orderId) => Api.get(`/order/${orderId}/to-book`);
export const updateServices = (orderId) => Api.get(`/order/${orderId}/update-services`);
export const getSuitcase = (request) => Api.get(`/order/suitcase`, request);
export const getOrders = (request) => Api.get(`/order/suitcase`, request);
export const addToSuitcase = (orderId) => Api.get(`/order/${orderId}/add-to-suitcase`);
export const removeFromSuitcase = (orderId) => Api.get(`/order/${orderId}/remove-from-suitcase`);
export const addOrder = (data) => Api.post(`/order/add`, data);
export const getOrderInsurances = (orderId) => Api.get(`/order/${orderId}/insurances`);
export const getOrderVisa = (orderId) => Api.get(`/order/${orderId}/visa`);
export const updateOrderTourists = ({orderId, touristId, data}) => Api.put(`/order/${orderId}/update-tourist/${touristId}`, data);
export const updateMarginTourists = ({orderId, data}) => Api.put(`/order/${orderId}/update-margin`, data);
export const deletedItemOrder = (orderId, itemId) => Api.delete(`/order/deleted/${orderId}/items/${itemId}`);
export const orderSend = (orderId, data) => Api.post(`/order/${orderId}/send`, data);

// Passport
export const getPassports = (touristId) => Api.get(`/tourist/${touristId}/passport`);
export const addPassport = ({touristId, data}) => Api.post(`/tourist/${touristId}/passport`, data);
export const getPassport = (touristId, passportId) => Api.get(`/tourist/${touristId}/passport/${passportId}`);
export const updatePassport = ({touristId, passportId, data}) => Api.put(`/tourist/${touristId}/passport/${passportId}`, data);
export const deletePassport = (touristId, passportId) => Api.delete(`/tourist/${touristId}/passport/${passportId}`);
export const passportSetMain = (touristId, passportId) => Api.get(`/tourist/${touristId}/passport/${passportId}/set-main`);

// Post
export const getPosts = (request) => Api.get(`/post`, request);
export const getPost = (postId) => Api.get(`/post/${postId}`);

// Post
export const getShareTrip = (orderId) => Api.get(`/share-trip/${orderId}`);

// Tourist
export const getTourists = () => Api.get(`/tourist`);
export const addTourist = ({data}) => {
	let formData = new FormData();
	Object.keys(data).forEach((key) => formData.append(key, data[key]));
	return Api.post(`/tourist`, formData, { headers: {
		"Content-Type": "multipart/form-data"
	} })
}
export const getTourist = (touristId) => Api.get(`/tourist/${touristId}`);
export const updateTourist = ({touristId, data}) => {
	let formData = new FormData();
	formData.append("_method", 'PUT')
	Object.keys(data).forEach((key) => formData.append(key, data[key]));
	return Api.post(`/tourist/${touristId}`, formData, { headers: {
		"Content-Type": "multipart/form-data"
	} })
};
export const deleteTourist = (touristId) => Api.delete(`/tourist/${touristId}`);
export const deletePhotoTourist = (touristId) => Api.get(`/tourist/${touristId}/delete-photo`);

// Transfer
export const transferSearch = (data) => Api.post(`/transfer/search`, data);
export const getTransferSearch = (storageId) => Api.get(`/transfer/search/fetch/${storageId}`);
export const transferDetails = (data) => Api.post(`/transfer/details`, data);

// TravelConcierge
export const TravelConcierge = (data) => {
	let formData = new FormData();
	Object.keys(data).forEach((key) => formData.append(key, data[key]));
	return Api.post(`/travel-concierge`, formData, { headers: {
		"Content-Type": "multipart/form-data"
	} })
};

// User
export const updateUser = (data) => {
	let formData = new FormData();
	Object.keys(data).forEach((key) => formData.append(key, data[key]));
	return Api.post(`/user`, formData, { headers: {
		"Content-Type": "multipart/form-data"
	} })
};
export const updateUserMargin = (data) => Api.put(`/user/margin`, data);
export const dataEditUser = (data) => Api.post(`/user/data-edit`, data);
export const deleteLogoUser = () => Api.get(`/user/delete-logo`);

export const cabinet = {
	courses: () => {return Api.get(`/cabinet/courses`)},
	user: {
		get: () => {return Api.get(`/cabinet/user`)},
		update: (data) => {return Api.put(`/cabinet/user`, data)}
	},
	order: {
		index: () => {return Api.get(`/cabinet/orders`)},
		get: (id) => {return Api.get(`/cabinet/orders/${id}`)},
		cancel: (id) => {return Api.get(`/cabinet/orders/${id}/cancel`)},
		refund: (id) => {return Api.get(`/cabinet/orders/${id}/refund`)},
		pay: (id) => {return Api.get(`/cabinet/orders/${id}/pay`)},
		invoice: (id) => {return Api.get(`/cabinet/orders/${id}/invoice`)},
		createInvoice: (id, data) => {return Api.post(`/cabinet/orders/${id}/invoice`, data)},
		paymentList: (id) => {return Api.get(`/cabinet/orders/${id}/payment-list`)},
	}
};

