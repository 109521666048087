<template>
	<div class="app-radio" :class='{disabled: disabled, [styled]: styled}'>
		<input
			type="radio"
			:id="`radio-${value}`"
			:name="`name-${value}`"
			:value="`value-${value}`"
			@input="$emit('input', value)"
			:checked="value === modelValue"
		/>
		<label :for="`radio-${value}`">
			<span class='app-radio-check'></span>
			<span>
				<span v-html='label'></span>
				<sup v-if="required">*</sup>
			</span>
		</label>
	</div>
</template>

<script>
export default {
	model: {
		prop: 'modelValue'
	},
	props:  {
		value: [String, Number],
		label: {
			type: String
		},
		modelValue: {
			default: ""
		},
		required: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		styled: {
			type: String,
			default: 'radio'
		}
	}
}
</script>


<style lang='scss'>
	@import "@/styles/global/variables.scss";
	@import "@/styles/global/mixins.scss";

	.app-radio {
		font-size: 14px;
		@include minw( $grid-breakpoints-md ) {
			font-size: 16px;
		}
		input {
			display: none;
		}
		&-check {
			padding-right: 10px * .8;
			@include minw( $grid-breakpoints-md ) {
				padding-right: 10px;
			}
			&::before, &::after {
				content: '';
				display: block;
				transition: $transition;
			}
			&::before {
				height: 22px * .8;
				width: 22px * .8;
				border: 1px solid rgba($black, .1);
				border-radius: 5px;
				@include minw( $grid-breakpoints-md ) {
					height: 22px;
					width: 22px;
				}
			}
		}
		label {
			display: flex;
			align-items: center;
			cursor: pointer;
			@include minw( $grid-breakpoints-md ) {
				&:hover {
					.app-radio-check::before {
						border-color: $green-persian;
					}
				}
			}
		}
		input:checked + label {
			.app-radio-check::before {
				border-color: $green;
			}
		}
		sup {
			top: -2px;
			color: $green;
		}
		&.disabled {
			pointer-events: none;
			label {
				color: rgba($black, .4);
				.app-radio-check::before {
					background-color: rgba($black, .05);
				}
			}
		}

		&.radio {
			.app-radio-check {
				&:before {
					width: 18px;
					height: 18px;
					border-width: 2px;
					border-radius: 50%;
				}
				&:after {
					position: absolute;
					top: 5px;
					left: 5px;
					width: 12px;
					height: 12px;
					background-color: $green-persian;
					border-radius: 50%;
					transform: scale(0);
					transition: transform $transition;
				}
			}
			input:checked + label {
				.app-radio-check:after {
					transform: scale(1);
				}
			}
		}

		&.checkbox {
			.app-radio-check {
				&::after {
					position: absolute;
					width: 12px * .8;
					height: 12px * .8;
					left: 6px * .8;
					top: 50%;
					transform: translateY(-50%);
					opacity: 0;
					visibility: hidden;
					background-color: $green;
					mask-image: url("../assets/svg/check.svg");
					mask-repeat: no-repeat;
					mask-size: contain;
					@include minw( $grid-breakpoints-md ) {
						width: 12px;
						height: 12px;
						left: 6px;
					}
				}
			}
			input:checked + label {
				.app-radio-check::after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
</style>
