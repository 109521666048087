import Vue from "vue";

import VButton from '@/components/VButton.vue';
import VTextField from '@/components/VTextField.vue';
import VSelect from '@/components/VSelect.vue';
import VIcon from '@/components/VIcon.vue';
import VDialog from '@/components/VDialog.vue';
import VAccordion from '@/components/VAccordion.vue';
import VCheckbox from '@/components/VCheckbox.vue';
import VRadio from '@/components/VRadio.vue';
import VSnackbar from '@/components/VSnackbar.vue';
import VAlert from '@/components/VAlert.vue';
import VChip from '@/components/VChip.vue';
import VPagination from '@/components/VPagination.vue';

import ADropdown from '@/components/account/ADropdown.vue';
import ASelect from '@/components/account/ASelect.vue';
import AAutocomplete from '@/components/account/AAutocomplete.vue';
import ASelectSearch from '@/components/account/ASelectSearch.vue';
import AUser from '@/components/account/AUser.vue';
import AStepper from '@/components/account/AStepper.vue';
import AMenu from '@/components/account/AMenu.vue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';
import ADatePicker from '@/components/account/ADatePicker.vue';
import ADatePickerMobile from '@/components/account/ADatePickerMobile';

const components = {
	VButton,
	VTextField,
	VSelect,
	VIcon,
	VDialog,
	VAccordion,
	VCheckbox,
	VRadio,
	VSnackbar,
	VAlert,
	VChip,
	VPagination,

	ADropdown,
	ASelect,
	AAutocomplete,
	ASelectSearch,
	AUser,
	AStepper,
	AMenu,

	DatePicker,
	ADatePicker,
	ADatePickerMobile
};

Object.entries(components).forEach(([name, component]) => Vue.component(name, component));
