<template>
	<a-select-search
		v-model='innerValue'
		@input='changeInput($event)'
		@blur='changeBlur'
		:placeholder='placeholder'
		:label='label'
		:prepend-icon='prependIcon'
		:append-icon='appendIcon'
		:loading='loading'
		:errors='errors'
		:small='small'
		@openSelect='innerValue && actionInitial ? changeInput(innerValue) : false'
		@input-clear="clearValue(), innerValue && actionInitial ? changeInput(innerValue) : false"
	>
		<template v-slot:dropdown="{ closeSelect }" v-if='innerValue || actionInitial'>
			<div class='a-select-search__dropdown--item' v-if='!loading && initialItems.length === 0'>
				<p class='a-select-search__dropdown--sub-title large' style='opacity: 1'>{{$t("account.no_results_found_for")}} <strong>“{{innerValue}}”</strong></p>
			</div>
			<template v-else>
				<template v-if='hasSlot("dropdown")'>
					<slot
						name='dropdown'
						:items='items'
						:update='update'
						:closeSelect='closeSelect'
						:value='value'
					/>
				</template>
				<template v-else>
					<ul class='a-select__dropdown--list'>
						<li class='a-select__dropdown--item'
							v-for="(option, i) of items"
							:key="i"
							@click="update(option), closeSelect()"

							:class='{"active": option[itemValue] == (returnObject ? (value ? value[itemValue] : null) : value)}'
							style='cursor: pointer'
						>
							<span>{{ option[itemName] }}</span>
							<v-icon icon='check' size='12' class='a-select__dropdown--check ml-auto' />
						</li>
					</ul>
				</template>
			</template>
		</template>
	</a-select-search>
</template>

<script>
	export default {
		data: () => ({
			innerValue: '',
			loading: false,
			initialItems: [],
			page: 0,
			size: 20,
			items: []
		}),
		props: {
			"item-value": {
				type: String,
				default: 'id'
			},
			"item-name": {
				type: String,
				default: 'name'
			},
			value: [String, Object],
			placeholder: String,
			label: String,
			"prepend-icon": {
				type: [String, Boolean],
				default: false
			},
			"append-icon": {
				type: [String, Boolean],
				default: false
			},
			errors: [Boolean, Array],
			requestName: Function,
			options: Array,
			small: {
				type: Boolean,
				default: false
			},
			actionInitial: {
				type: Boolean,
				default: false
			},
			"return-object": {
				type: Boolean,
				default: false
			},
			initialLoading: {
				type: Boolean,
				default: false
			}
		},
		created() {
			this.initialInnerValue();
			if(this.initialLoading) {
				this.changeInput(this.innerValue, true);
			}
		},
		methods: {
			changeInput(item, initial) {
				if(this.requestName) {
					if (this.request) this.request.cancel();
					const axiosSource = this.$http.CancelToken.source();
					this.request = { cancel: axiosSource.cancel };

					this.page = 0;
					if(item.length || item.length === 0 && this.actionInitial) {
						this.loading = true
						setTimeout(() => {
							this.requestName({search: item}, { cancelToken: axiosSource.token })
								.then(res => {
									this.initialItems = res.data;
									initial ? this.initialInnerValue() : false
								})
								.catch(err => !this.$http.isCancel(err) )
								.finally(() => this.loading = false)
						}, 300)
					} else {
						this.initialItems = []
						this.$emit("input", '')
					}
				} else {
					this.page = 0;
					if(item.length || this.actionInitial) {
						this.initialItems = this.options.filter((el) => el[this.itemName].toLowerCase().includes(item.toLowerCase()));
					} else {
						this.initialItems = []
						this.$emit("input", '')
					}
				}
			},
			changeBlur() {
				if(this.returnObject) {
					if(this.value && this.value[this.itemValue]) {
						this.innerValue = this.value[this.itemName]
					} else if(this.innerValue && this.initialItems.length) {
						this.innerValue = this.initialItems[0][this.itemName]
						this.$emit('input', this.initialItems[0]);
					} else if(this.innerValue && this.initialItems.length === 0) {
						this.clearValue();
					}
				} else {
					if(this.value) {
						this.initialInnerValue();
					} else if(this.innerValue && this.initialItems.length) {
						this.innerValue = this.initialItems[0][this.itemName]
						this.$emit('input', this.initialItems[0][this.itemValue]);
					} else if(this.innerValue && this.initialItems.length === 0) {
						this.clearValue();
					}
				}
			},
			update(item) {
				this.$emit('input', this.returnObject ? item : item[this.itemValue]);
				this.innerValue = item[this.itemName];
			},
			loadMore() {
				this.page = this.page + 1;
				this.items = this.initialItems.slice(0, this.size * this.page);
			},
			clearValue() {
				this.innerValue = '';
				this.$emit('input', '');
			},
			hasSlot(name = 'default') {
				return !!this.$slots[ name ] || !!this.$scopedSlots[ name ];
			},
			initialInnerValue() {
				if(this.returnObject) {
					this.innerValue = this.value ? this.value[this.itemName] : '';
				} else {
					this.innerValue = this.initialItems.find(el => el[this.itemValue] === this.value) ? this.initialItems.find(el => el[this.itemValue] === this.value)[this.itemName] : '';
				}
			}
		},
		watch: {
			value() {
				this.initialInnerValue();
			},
			initialItems() {
				let list = document.querySelector('.a-select__dropdown');
				let self = this;

				if(list) list.addEventListener('scroll', function() {
					if (list.scrollTop + list.clientHeight >= list.scrollHeight) {
						self.loadMore();
					}
				});

				this.loadMore();
			}
		}
	}
</script>
