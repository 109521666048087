export default [
	{
		path: 'home',
		name: 'home',
		meta: { requiresAuth: true },
		component: () => import('@/views/account/home'),
		redirect: { name: 'hotels' },
		children: [
			{
				path: 'flights',
				name: 'flights',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/home/flights')
			},
			{
				path: 'hotels',
				name: 'hotels',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/home/hotels')
			},
			{
				path: 'transfers',
				name: 'transfers',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/home/transfers')
			},
			{
				path: 'cars',
				name: 'cars',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/home/cars')
			},
			{
				path: 'charter-flight',
				name: 'charter-flight',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/home/charter-flight')
			},
			{
				path: 'groupmaker',
				name: 'groupmaker',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/home/groupmaker')
			},
			{
				path: 'insurance',
				name: 'insurance',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/home/insurance')
			}
		]
	},
	{
		path: 'orders',
		name: 'orders',
		meta: { requiresAuth: true },
		component: () => import('@/views/account/orders')
	},
	{
		path: 'trips',
		meta: { requiresAuth: true },
		component: {
			render: h => h('router-view')
		},
		children: [
			{
				path: '',
				name: 'trips',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/trips')
			},
			{
				path: ':orderId',
				name: 'trips-order',
				meta: { requiresAuth: true },
				component: {
					render: h => h('router-view')
				},
				children: [
					{
						path: 'itinerary',
						name: 'itinerary',
						meta: { requiresAuth: true },
						component: () => import('@/views/account/itinerary'),
					},
					{
						path: 'search',
						name: 'search',
						meta: { requiresAuth: true },
						component: {
							render: h => h('router-view')
						},
						children: [
							{
								path: 'flights/:storageId',
								name: 'flights-search',
								meta: { requiresAuth: true, isStatic: true },
								component: () => import('@/views/account/search/flights'),
								children: [
									{
										path: 'offer/:code',
										name: 'flights-offer',
										meta: { requiresAuth: true },
										component: () => import('@/views/account/offers/flights')
									}
								]
							},
							{
								path: 'hotels',
								meta: { requiresAuth: true },
								component: {
									render: h => h('router-view')
								},
								children: [
									{
										path: ':storageId',
										name: 'hotels-search',
										meta: { requiresAuth: true, isStatic: true },
										component: () => import('@/views/account/search/hotels')
									}
								]
							},
							{
								path: 'transfers',
								meta: { requiresAuth: true },
								component: {
									render: h => h('router-view')
								},
								children: [
									{
										path: ':storageId',
										name: 'transfers-search',
										meta: { requiresAuth: true, isStatic: true },
										component: () => import('@/views/account/search/transfers')
									}
								]
							},
							{
								path: 'cars',
								meta: { requiresAuth: true },
								component: {
									render: h => h('router-view')
								},
								children: [
									{
										path: ':storageId',
										name: 'cars-search',
										meta: { requiresAuth: true, isStatic: true },
										component: () => import('@/views/account/search/cars')
									}
								]
							},
							{
								path: 'charter-flight/:storageId',
								name: 'charter-flight-search',
								meta: { requiresAuth: true, isStatic: true },
								component: () => import('@/views/account/search/charter-flight')
							},
							{
								path: 'insurance',
								meta: { requiresAuth: true },
								component: {
									render: h => h('router-view')
								},
								children: [
									{
										path: ':storageId',
										name: 'insurance-search',
										meta: { requiresAuth: true, isStatic: true },
										component: () => import('@/views/account/search/insurance')
									}
								]
							},
						]
					}
				]
			}
		]
	},
	{
		path: 'hotel/:code',
		name: 'hotels-result',
		meta: { requiresAuth: true },
		component: () => import('@/views/account/result/hotels')
	},
	{
		path: 'comparison',
		meta: { requiresAuth: true },
		component: {
			render: h => h('router-view')
		},
		children: [
			{
				path: 'flight/:comparisonId',
				name: 'comparison-flights',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/comparison/flights')
			},
			{
				path: 'hotel/:comparisonId',
				name: 'comparison-hotels',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/comparison/hotels')
			},
			// {
			// 	path: ':orderId/search/hotels/:storageId/result/:code',
			// 	name: 'comparison-hotels-rooms',
			// 	meta: { requiresAuth: true },
			// 	component: () => import('@/views/account/comparison/hotels-rooms')
			// },
			{
				path: 'transfer/:comparisonId',
				name: 'comparison-transfers',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/comparison/transfers')
			},
			{
				path: 'rentTransfer/:comparisonId',
				name: 'comparison-cars',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/comparison/cars')
			},
			{
				path: 'charterFlight/:comparisonId',
				name: 'comparison-charter-flight',
				meta: { requiresAuth: true },
				component: () => import('@/views/account/comparison/charter-flight')
			}
		]
	},
	{
		path: 'share-flight/:comparisonId',
		name: 'share-flights',
		meta: { all: true },
		component: () => import('@/views/account/share/flights')
	},
	{
		path: 'share-hotel/:comparisonId',
		name: 'share-hotels',
		meta: { all: true },
		component: () => import('@/views/account/share/hotels')
	},
	{
		path: 'share-transfer/:comparisonId',
		name: 'share-transfers',
		meta: { all: true },
		component: () => import('@/views/account/share/transfers')
	},
	{
		path: 'share-charter-flight/:comparisonId',
		name: 'share-charter-flight',
		meta: { all: true },
		component: () => import('@/views/account/share/charter-flight')
	},
	{
		path: 'share-trip/:orderId',
		name: 'share-trip',
		meta: { all: true },
		component: () => import('@/views/account/share/share-trip'),
	},
	{
		path: 'tourists',
		name: 'tourists',
		meta: { requiresAuth: true },
		component: () => import('@/views/account/tourists')
	},
	{
		path: 'favourite',
		name: 'favourite',
		meta: { requiresAuth: true },
		component: () => import('@/views/account/favourite')
	},
	// {
	// 	path: 'settings',
	// 	name: 'settings',
	// 	meta: { requiresAuth: true },
	// 	component: () => import('@/views/account/settings')
	// },
]
