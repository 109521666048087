import Vue from 'vue';
import VueRouter from 'vue-router';
import { Trans } from '@/plugins/Translation';
import main from './main';
import auth from './auth';
import account from './account';
import hc from './hc';
import services from './services';
import crm from './crm';

const children = [
	...main,
	...auth,
	...account,
	...hc,
	...crm,
	...services
];

Vue.use(VueRouter);

function getLocaleRegex() {
	let reg = '';
	process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',').forEach((locale, index) => {
		reg = `${reg}${locale}${index !== process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',').length - 1 ? '|' : ''}`;
	});
	return `(${reg})`;
}

const router = new VueRouter({
	mode: 'history',
	base: __dirname,
	scrollBehavior (to, from, savedPosition) {
		if (to.hash.length > 1) {
			return {
				selector: to.hash,
				behavior: 'smooth',
				offset: { x: 0, y: document.querySelector(to.meta.requiresAuth ? '.a-header' : '.header').getBoundingClientRect().height }
			}
		}

		if(to.name === 'flights-offer' || from.name === 'flights-offer') return false;
		if(to.name === 'hotels-result' && to.query.storageId !== from.query.storageId) return false;

		return savedPosition ? savedPosition : { x: 0, y: 0 };
	},
	routes: [
		{
			path: `/:lang${getLocaleRegex()}?`,
			component: {
				render: h => h('router-view')
			},
			beforeEnter: Trans.routeMiddleware,
			children
		}
	]
})

router.beforeEach((to, from, next) => {
	if(to.matched.some(record => record.meta.all)) {
		next()
	} else {
		if(to.matched.some(record => record.meta.requiresAuth) && window.localStorage.getItem('access_token') == null) {
			next({ name: "sign-in", params: {lang: to.params.lang}})
		}	else if(to.matched.some(record => record.meta.guest) && window.localStorage.getItem('access_token')) {
			next({ name: "home", params: {lang: to.params.lang}})
		} else {
			next()
		}
	}
})

export default router;
