<template>
  <div class="app-checkbox" :class="{ disabled: disabled }">
    <input
      type="checkbox"
      :id="`checkbox-${name}`"
      :value="value"
      @change="updateInput"
      :checked="shouldBeChecked"
    />
    <label :for="`checkbox-${name}`">
      <span class="app-checkbox-check"></span>
      <span>
        <span v-html="label"></span>
        <sup v-if="required">*</sup>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    name: [String, Number],
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      default: '',
    },
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;

      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/global/variables.scss';
@import '@/styles/global/mixins.scss';

.app-checkbox {
  font-size: 14px;
  @include minw($grid-breakpoints-md) {
    font-size: 16px;
  }
  input {
    display: none;
  }
  &-check {
    padding-right: 10px * 0.8;
    @include minw($grid-breakpoints-md) {
      padding-right: 10px;
    }
    &::before,
    &::after {
      content: '';
      // position: absolute;
      display: block;
      transition: $transition;
    }
    &::before {
      height: 22px * 0.8;
      width: 22px * 0.8;
      border: 1px solid rgba($black, 0.1);
      border-radius: $border-radius;
      // top: 50%;
      // left: 0;
      // transform: translateY(-50%);
      @include minw($grid-breakpoints-md) {
        height: 22px;
        width: 22px;
      }
    }
    &::after {
      position: absolute;
      width: 12px * 0.8;
      height: 12px * 0.8;
      left: 6px * 0.8;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      visibility: hidden;
      background-color: $green;
      mask-image: url('../assets/svg/check.svg');
      mask-repeat: no-repeat;
      mask-size: contain;
      @include minw($grid-breakpoints-md) {
        width: 12px;
        height: 12px;
        left: 6px;
      }
    }
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include minw($grid-breakpoints-md) {
      &:hover {
        .app-checkbox-check::before {
          border-color: rgba($black, 0.5);
        }
      }
    }
  }
  input:checked + label {
    .app-checkbox-check::before {
      border-color: $green;
    }
    .app-checkbox-check::after {
      opacity: 1;
      visibility: visible;
    }
  }
  sup {
    top: -2px;
    color: $green;
  }
  &.disabled {
    pointer-events: none;
    label {
      color: rgba($black, 0.4);
      .app-checkbox-check::before {
        background-color: rgba($black, 0.05);
      }
    }
  }
}
</style>
