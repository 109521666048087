export const mutations = {
	currency(state, payload) {
		state.currency = payload;
	},
	exchange_rates(state, payload) {
		state.exchange_rates = payload;
	},
	suitcase_total(state, payload) {
		state.suitcase_total = payload;
	}
}
